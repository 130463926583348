import { useEffect } from "react"
import * as React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"

import { useIntl } from "gatsby-plugin-intl"

const Index = ({ data }) => {
  useEffect(() => {
    window.location.href = "/home-en"
  }, [])

  const intl = useIntl()

  return (
    <Seo
      title={intl.formatMessage({ id: "header_intl.home" })}
      description={intl.formatMessage({ id: "header_intl.seo" })}
    />
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
